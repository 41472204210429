import { useEffect, useState } from "react";
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import Container from 'react-bootstrap/Container'
import { FaWallet } from "react-icons/fa";
import { useAuth } from "../context-api/AuthConsumer";
import { useNavigate } from "react-router-dom";
import Tiktok from "../img/tiktok.png"


const Access = () => {
    const [counter, setCounter] = useState(0);
    const { updateWallet, logout } = useAuth()
    const { available, user, getToday, updateStartDate, updateCounter, getStart, getUser,getEnd, updateLock } = useAuth()
    const [limit, setLimit] = useState(0)
    const [lock, setLock] = useState(false)
    const navigate = useNavigate()

    const handleAvailable = () => {
        console.log('available',available)
    }
    const submitCount = () => {
        if(user.counter < 180){
            getEnd(user?.username)
            getUser()
        }else{
            window.alert(`Você ultrapassou o limite de visualizações por hoje. Volte ${formatDate(user?.start)}`)
            navigate('/home', {replace: true})
        }
    }

    useEffect(() => {
        getUser()
        getToday()
    },[])

    const formatDate = (start) => {
        let date = new Date(start)
        let secondFormat = date.getSeconds().toString()
        let minFormat = date.getMinutes().toString();
        let hourFormat = date.getHours().toString();
        let day = date.getDate().toString();
        let dayFormat = (day.length == 1) ? '0'+day : day;
        let month = (date.getMonth()+1).toString();
        let monthFormat = (month.length == 1) ? '0'+month : month;
        let yearFormat = date.getFullYear();
        return dayFormat+"/"+monthFormat+"/"+yearFormat+" às "+hourFormat+":"+minFormat+":"+secondFormat
    }

    const handleEvents = (e) => {
        e.preventDefault()
        if (user?.counter < 180 ){
            window.open('https://vm.tiktok.com/')
            updateStartDate(available)
            updateLock("true")
            // getUser()
            getStart(user?.username)
            
        }else{
            if (new Date(user?.start) < new Date()){
                updateStartDate(available)
                updateCounter(user?.username)
                setLimit(0)
                user.counter = 0;
            }
            else{
                window.alert(`Você ultrapassou o limite de visualizações por hoje. Volte ${formatDate(user?.start)}`)
            }
        }
    }

    const handleLogout = () => {
        logout().then(() => navigate('/login', {replace: true}))
    }
    
    return(
        <>
            <div className="main text-center" style={{backgroundColor:'black', height: '100vh'}}>
                <Navbar className="col-12" bg="black" expand="lg" style={{backgroundColor:'black', display:'flex'}}>
                    <Container className=" col-6" style={{color:'red', backgroundColor:'black', display:'block', marginTop: '12px',justifySelf:'end'}}>
                        
                    </Container>
                </Navbar>
                <div className="col-12" style={{ display: 'block',backgroundColor:'black',justifyContent: 'center', alignContent:'center', alignItems:'center'}}>
                    <Container className="col-12" style={{color:'red', display:'flex',  backgroundColor:'black'}}>
                        <FaWallet 
                        onClick={(e) => submitCount(e)} 
                        size={30} />
                        <br/>
                    </Container>
                    <strong className="col-2" style={{display:'flex',padding:'0' ,color:'red'}}>Acessar Carteira</strong>
                    <div style={{width:'100%', backgroundColor:'black' ,margin: 'auto',display: 'block'}}>
                    
                    <Col className="col-12">
                        <Image src={Tiktok} rounded style={{width:'70%'}}/> 
                        <Col className="col-12" style={{ marginTop:'2rem',marginBottom:'2rem' }}>
                            <h4
                            style={{ color :'white', fontWeight:'bold', fontSize:'3.3rem', marginTop:'2rem', alignItems:'center', margin:'auto' }}
                            >
                                Falta Pouco!
                            </h4>
                            <div>

                            <h6 style={{ color :'white', fontSize:'1.3rem', marginTop:'3em', alignItems:'center', margin:'auto', display:'block' }}>   
                                Para começar a ganhar clique no botão abaixo para ir ao Tiktok e assista seus vídeos 😃.
                            </h6>
                            <h6 style={{ color :'red', fontSize:'1rem', marginTop:'2.1em', marginBottom:'2rem' ,display:'block', width:'100%' }}>
                                É necessário deixar esta aba e o navegador abertos!!!
                            </h6>
                            <Button onClick={(e)=>{
                                handleEvents(e); 
                                handleAvailable(); 
                                setLock(true);
                                }
                                }
                                disabled={!user?true:false} 
                                style={{ color :'black', marginBottom:'24px',marginTop:'1.5rem', fontWeight:'bold' , backgroundColor:'#00eae7', border:'1px solid #00eae7', borderRadius:'0.3rem', height:'3rem', margin:'auto', display:'block',width:'100%' }}>
                                Abrir Tiktok!
                            </Button> 
                            </div>
                            <div className="col-12" style={{marginBottom:'6rem', marginTop:'2rem'}}>
                            <Button onClick={()=>handleLogout()} style={{color:'black',width:'100%' ,backgroundColor:'red', borderColor:'black',  justifySelf:'end', marginBottom:'2rem'}}>
                                Sair
                            </Button> 
                            </div>
                        </Col>
                    </Col>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Access;