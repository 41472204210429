import { Navigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from '../context-api/AuthConsumer';
import Register from '../register/register'
import Login from '../login/login'
import Dash from '../dash/dash'
import Access from '../access/access'

export const Router = () => {
  const { auth, isInitialLoading } = useAuth();

  if (isInitialLoading ) {
    return null;
  }

  if (!auth) {
    return (
      <Routes>
        <Route index element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }


  return (
    <Routes>
      <Route path="home" element={<Dash/>} />
      <Route path="access" element={<Access/>} />
      <Route path="*" element={<Navigate to="home" replace />} />

  </Routes>
  );
};