import React from "react";
import {Router} from './router/index'
import { AuthProvider } from './context-api/AuthProvider'
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}


export default App;