import { useRef, useState, useEffect } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from "react-bootstrap/Image";
import {useAuth } from "../context-api/AuthConsumer"
import { useNavigate } from "react-router-dom";
import Tiktok from "../img/tiktok.png"
import {Oval} from "react-loader-spinner";

const Login = () => {
    const userRef = useRef();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState(false);
    const { login } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      userRef.current.focus();
    },[]);

    useEffect(() => {
      setErrMsg(false);
    }, [username, password]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true)
      try {
        const accessToken = await login(username, password);
        if (accessToken) {
          setUsername("");
          setPassword("");
          navigate('/access', { replace: true });
        } else {
          setErrMsg("User not found");
          throw new Error("User not found")
      }
      } catch (err) {
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setErrMsg(true);
          setLoading(false)
        } else if (err.response?.status === 401) {
          setErrMsg("Unauthorized");
        } else if (err.response?.status === 500) {
          setErrMsg("Unauthorized");
         } else {
          setErrMsg("Login Failed");
        }
      }
    };

    return(
        <>
        <div className="login">  
          <Row className="col-12">   
            <Col
              className="col-12"
              style={{ height:'15rem' ,justifyContent:'center', display:'block', alignItems:'center', alignContent:'center'}}
            >
                <Image
                  className="container col-12"
                  src={Tiktok}
                  rounded
                  style={{width:'15rem',justifyContent:'center', display:'flex', alignItems:'center', alignContent:'center'}}
                /> 
            </Col>
            <Alert className="container col-11" style={{marginLeft:'1.7rem'}} variant={'primary'}>
                Não possui uma conta?{' '}<a href="/register" style={{fontWeight:'bold', color:'blue'}}>Cadastre-se</a>
            </Alert>
          </Row>
          <Row className="col-12">
            <Col
              className="container text-center col-12"
              style={{justifyContent:'center', display:'block', alignItems:'center', alignContent:'center'}}
            >
              <Form
                style={{
                    borderStyle:'solid',
                    borderRadius:'0.5rem',
                    borderColor:'white',
                    boxShadow:'10px 10px 50px #aaaaaa',
                    margin:'1rem',
                    paddingTop:'3rem',
                    paddingBottom:'3rem',
                    }}
                    className='container'
                onSubmit={(e)=>handleSubmit(e)}
              >
                  <Form.Group className="mb-3">
                    <Form.Label><strong>Email</strong></Form.Label>
                    <br/>
                    <Form.Control
                      className="text-center" 
                      style={{height:'3rem', width:'100%',}}
                      id="username"
                      ref={userRef}
                      placeholder="Digite seu Email"
                      autoComplete="off"
                      onChange={(e) => setUsername(e.target.value.toLowerCase())}
                      value={username}
                      required
                    />
                  </Form.Group>
                  <br/>
                  <Form.Group className="mb-3">
                  <Form.Label><strong>Senha</strong></Form.Label>
                    <br/>
                    <Form.Control
                      className="text-center"
                      type="password"
                      id="password"
                      style={{height:'3rem', width:'100%',}}
                      placeholder="Digite sua senha"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      required
                    />
                  </Form.Group>
                  <br/>
                  {!loading ?
                    <Button 
                      type="submit"
                      style={{ color :'white', backgroundColor:'black', borderRadius:'0.3rem', width:'100%', height:'2.5rem' }}
                    >
                    Entrar
                  </Button> :
                  <div style={{width:'100%', height:'2.5rem', justifyContent:'center', display:'flex', alignContent:'center', alignItems:'center'}}>
                    <Oval
                      secondaryColor="#ff174d"
                      color="#00eae7"
                      width={50}
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                  }
                  { {errMsg} ?
                  <Alert variant="danger" hidden={!errMsg} style={{justifyContent:'center', display:'flex', alignItems:'center', alignContent:'center'}}>   
                      {"Usuário ou senha incorretos"}
                  </Alert>
                  : ''
                }
              </Form>
          </Col>
      </Row>
        </div>
      </>
    )
}

export default Login