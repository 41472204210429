import { useContext } from 'react';
import { AuthContext } from './AuthContext';

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new Error('You should only use auth object inside auth provider.');
  }

  return auth;
};
