import { useState, useEffect } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import FormText from "react-bootstrap/FormText";
import ProgressBar from "@ramonak/react-progress-bar";
import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal} from 'react-bootstrap';
import { useAuth } from "../context-api/AuthConsumer";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { FaWallet } from "react-icons/fa";
import {Oval} from "react-loader-spinner";
import Tiktok from "../img/tiktok.png"
const Dash = () => {
    const [modalShow, setModalShow] = useState(false);
    const [dateend, setDateend] = useState()
    const [wallet, setWallet] = useState(0)
    const [counter, setCounter] = useState()
    const [result, setResult] = useState()
    const { logout, user, getUser, updateWallet, resultWallet, getWallet, updateLock,setTimeStart, timestart,setEnd, end, getEnd } = useAuth();
    const navigate = useNavigate()

    const handleLogout = () => {
        logout().then(() => navigate('/login', {replace: true}))
    }

    useEffect(() => {
        getUser()
    },[])

    useEffect(() => {
        if(user?.wallet){
            setWallet(Math.abs((moment(end).diff(moment(timestart)))/60000))
            setCounter(Math.abs((moment(end).diff(moment(timestart)))/60000))
            
        }
        getWallet()
    },[user?.timeend])


    useEffect(() => {
        console.log('end-start',Math.abs((moment(end).diff(moment(timestart)))/60000))
    },[wallet])

    return(
        <div className="dash col-12" style={{backgroundColor:'black', width:'100%', paddingBottom:'5rem'}}>
            <Row className="col-12" style={{backgroundColor:'black'}}>
                <Navbar className="row" bg="black" expand="lg" style={{backgroundColor:'black', display:'flex', width:'100vw', marginTop:'8px'}}>
                    <div className="col-12 mt-2" style={{color:'red', backgroundColor:'black',display:'flex', borderColor:'black', justifyContent:'left'}}>
                        <FaWallet size={30} />
                        <p style={{marginLeft:'1rem', marginTop:'0.5rem'}}>{resultWallet ?
                        (`R$ ${(Number(resultWallet)*1.66).toFixed(2)}`)
                        :
                        <>
                            carregando...
                        </>
                        }
                        </p>
                        <div className="col-6">
                        <p className="" style={{marginLeft:'1rem', marginTop:'0.5rem'}}>
                            Olá, {user?.email}
                        </p>
                        </div>
                    </div>
                </Navbar>
                <Row className="col-12" style={{backgroundColor:'black'}}>
                    <Col style={{backgroundColor:'black'}} className="container col-12 offset-1">
                        <div style={{width:'100%', margin: 'auto',display: 'block'}}>
                            <Image
                                src={Tiktok}
                                rounded
                                style={{width:'100%', display:'block', float:'center'}}
                            /> 
                        </div>
                        <Col className="col-12">
                            <p style={{color:'red'}}>BRL: {resultWallet ?
                                (`${((Number(resultWallet))*1.66).toFixed(2)}`)
                                :
                                <>
                                    carregando...
                                </>
                                }
                            </p>
                            <p style={{marginLeft:'1rem'}}>
                            </p>

                            <ProgressBar
                                textDecoration='none'
                                className="wrapper"
                                labelColor="white"
                                bgColor="#00FF00"
                                labelClassName="label"
                                animateOnRender={true}
                                borderRadius="50px"
                                labelAlignment=' '
                                maxCompleted={100}
                                completed={
                                    resultWallet ? +((Number(resultWallet))/36).toFixed(2): 'carregando'
                                } 
                            />
                            <Button 
                                type="submit"
                                style={{ color:'black', fontWeight:'bold', fontSize:'1.3rem' ,backgroundColor:'#00eae7', borderRadius:'0.5rem', width:'100%', marginTop:'2rem' }}
                                disabled={wallet !== 0 && user?.timeend?(user?.lock==="true"?false:true):true}                                // onClick={user && resultWallet > 180000 ? () =>  window.location.assign('http://github.com') : ()=>setModalShow(true)}
                                onClick={(e)=>{updateWallet(wallet,counter);getWallet();getUser();updateLock("false");setEnd(0);setTimeStart(0)}}
                            >
                                Atualizar Carteira
                            </Button>
                            <Button 
                                type="submit"
                                style={{ color:'black', fontWeight:'bold', fontSize:'1.3rem' ,backgroundColor:'#00eae7', borderRadius:'0.5rem', width:'100%', marginTop:'2rem' }}
                                onClick={user && resultWallet > 180000 ? () =>  window.location.assign('http://github.com') : ()=>setModalShow(true)}
                            >
                                Quero sacar meu dinheiro
                            </Button>
                            <a  
                                href="/access" 
                                style={{textDecoration:'none'}}>
                                <Col
                                    style={{backgroundColor:'#ff174d', marginTop:'2rem', borderRadius:'0.5rem', border:'2px solid #00eae7'}}>
                                    <Image
                                        src={Tiktok}
                                        rounded
                                        style={{width:'5rem',justifyContent:'center', display:'flex', alignItems:'center', alignContent:'center'}}
                                    />
                                    <h5 style={{fontWeight:'bold' , marginTop:'3rem', marginBottom:'3rem',width:'5rem',justifyContent:'center', display:'flex', alignItems:'center', alignContent:'center', color:'black'}}>
                                        Tiktok
                                    </h5>
                                </Col>
                            </a>
                            <small className="text-center" style={{color:'red', margin:'1rem', alignItems:'center', justifyContent:'center', display:'block'}}>
                                Clique no botão acima para assistir
                            </small>
                            <Col className="">
                                <Button onClick={()=>handleLogout()} style={{color:'black',display: 'block',width:'100%',justifyContent: 'center', alignContent:'center', alignItems:'center' ,backgroundColor:'red', borderColor:'black'}}>
                                    Sair
                                </Button> 
                            </Col>
                            
                            <Modal className="text-center" show={modalShow} onHide={() => setModalShow(false)}>
                                <Modal.Header>
                                    <Modal.Title className="col-12" id="contained-modal-title-vcenter">
                                        Aviso!
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        Você precisa bater a meta de R$ 6.000,00 para sacar. Continue assistindo para liberar seu saque.
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn-danger col-12" onClick={()=>setModalShow(false)}>Fechar</Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Col>
                </Row>
            </Row>
        </div>
    )
}

export default Dash;