import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { AuthContext } from "./AuthContext";


const LOGIN_URL = '/token';
const REGISTER_URL = '/register';
const WALLET_URL = '/wallet';
const IS_TODAY = '/is_today';
const START_DATE_URL = '/start_date'
const RESTART_COUNTER = '/restart_counter'
const TIMESTART = "/timestart"
const TIMEEND = "/timeend"
const UPDATELOCK = "/update_lock"


export const AuthProvider = ({ children }) => {
  const [authToken, setAuth] = useState("");
  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [user, setUser] = useState(undefined)
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const [available,setAvailable] = useState()
  const [errResponse, setErrResponse] = useState(false)
  const [start,setStart] = useState('')
  const [timestart,setTimeStart] = useState('')
  const [end, setEnd] = useState('')
  const [resultWallet, setResultWallet] = useState('')
  const navigate = useNavigate();

  // useEffect(() => {
  //   if(email){
  //     getUser()
  //   }
  // },[])

  useEffect(() => {
    const token = readCookie()
    console.log("token", token)
    setAuth(token.token ?? null);
    setEmail(token.email ?? null);
    setUsername(token.token ?? null)

    if (email) {
      getUser()
      // getStart()
      // getEnd()
    }

    setIsInitialLoading(false);
  }, [authToken, email,  isLoading]);

  const readCookie = () => {
    let token = Cookies.get("token-tiktok");
    let email = Cookies.get("email-tiktok");
    return {token: token || null, email: email || null}
  };

  const login = async (username, password) => {
    setIsLoading(true)

    const response = await axios.post(
      LOGIN_URL,
      new URLSearchParams({username, password}),
      {
        headers: { 'Content-Type' : 'application/x-www-form-urlencoded'},
        // withCredentials: true,
      }
      
    );

    const accessToken = response?.data?.access_token;
    const user = response?.data?.user;

    if(accessToken) {
      Cookies.set("token-tiktok", accessToken);
      Cookies.set("email-tiktok", user.email);

      setUser(user)
      setIsLoading(false)

      return accessToken
    } else {
      setIsLoading(false)
      throw new Error("Erro ao fazer login. Confira os campos e tente novamente.")
    }
  }

  const register = async (username, password, email, cpf, ddd, whatsapp, data_nascimento, genero, timestart, timeend, lock) => {
  //   const headers = { 
  //     "WWW-Authenticate": "Bearer",
  //     "Content-Type": "application/json",
  //     "access-control-allow-credentials": true 
  // };
    const response = await axios.post(
      REGISTER_URL,
      JSON.stringify({
        username,
        password,
        email,
        cpf,
        ddd,
        whatsapp,
        wallet: 0,
        start:new Date(),
        counter:0,
        data_nascimento,
        genero,
        timestart:0,
        timeend:0,
        lock:"false"
      }),
      {
        headers : {
        "access-control-allow-credentials": '*',
        'mode':'cors',
        'Content-Type':'application/json'}
      
      }
    ).then((response) => {
      if (response?.status === 201){
        navigate('/home', { replace: true });
      }
      else if (response?.status === 200){
        navigate('/home', { replace: true });
      }
    })
    .catch(err=>{window.alert('Erro: email informado já está cadastrado em outra conta');setErrResponse(true)});

    const accessToken = response?.data?.access_token;
    const user = response?.data?.user;

    if(accessToken && user) {
      Cookies.set("token-tiktok", accessToken);
      Cookies.set("email-tiktok", user.email);

      setUser(user)
      setIsLoading(false)

      return accessToken
    }
    // else {    
    //   setIsLoading(false)
    //   throw new Error("Erro ao cadastrar. Confira os campos e tente novamente.")
    // }
  }

  const getUser = async () => {
    console.log("email", username)
    const response = await axios.get(
      `${WALLET_URL}/?username=${username}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    setUser(response.data)
    setStart(response.data.start)
    setTimeStart(response.data.timestart)
    setEnd(response.data.timeend)
  }

  const getToday = async () => {
    // getUser()
    console.log('start', start)
    console.log('day hoje', new Date().getDay)
    const response = await axios.get(
      `${IS_TODAY}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    setAvailable(response.data)
  }
  
  const updateWallet = (wallet, counter) => {
    console.log('end effect',end)
    console.log('total counter po',Math.abs(Number(user?.counter)+Number(counter)))
    if(Math.abs(Number(user?.counter)+Number(counter))>180){
      const response = axios.put(
        WALLET_URL,
        JSON.stringify({ username, wallet:Math.abs(179.65 - Number(user?.counter)), counter:0 }),
        {
          headers: { "Content-Type": "application/json" },
        }
      ).then((response) => {
        if(response.status === 200){
          // clearTimes()
          console.log(response.status)
          // navigate('/home', {replace: true})
        }
      });
      setResultWallet(response?.data?.wallet)
      setUser(response?.data)  
    }else{
    const response = axios.put(
      WALLET_URL,
      JSON.stringify({ username, wallet, counter }),
      {
        headers: { "Content-Type": "application/json" },
      }
    ).then((response) => {
      if(response.status === 200){
        // clearTimes()
        console.log(response.status)
        // navigate('/home', {replace: true})
      }
    });
    setResultWallet(response?.data?.wallet)
    setUser(response?.data)}
  }

  const updateStartDate = async () => {
    await axios.put(
    START_DATE_URL,
    JSON.stringify({ "username":username, 'start': available}),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  }

  const updateCounter = async (username) => {
    await axios.put(
      RESTART_COUNTER,
      JSON.stringify({username, counter: 0 }),
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  const getStart = async (username) => {
    await axios.put(
      TIMESTART,
      JSON.stringify({username, timestart: new Date() }),
      {
        headers: { "Content-Type": "application/json" },
      }
    ).then((response)=>{
      if(response.status === 200){
        // setTimeStart(response.timeend)
      }
    });
  }

  const getEnd = async (username) => {
    await axios.put(
      TIMEEND,
      JSON.stringify({username, timeend: new Date() }),
      {
        headers: { "Content-Type": "application/json" },
      }
    ).then((response)=>{
      if(response.status === 200){
        // setEnd(response.timeend)
        navigate('/home', {replace: true})
        // updateWallet()
      }
    })
  }

  const getWallet = async () => {
    const response =  await axios.get(
      `${WALLET_URL}/?username=${username}`,
    ).then((response)=>{
      if(response.status === 200){
        setResultWallet(response.data.wallet)    
      }
    })
  }

  const updateLock = (lock) => {
    axios.put(
      UPDATELOCK,
      JSON.stringify({username, "lock":lock }),
      {
        headers: { "Content-Type": "application/json" },
      }
    ).then((response)=>{
      if(response.status === 200){
        getWallet()
      }
    })
  }    

  useEffect(() => {
    console.log('users', user)
  },[])


  const logout = async () => {
    Cookies.set("token-tiktok", '');
    Cookies.set("email-tiktok", '');
    setAuth("");
  };

  return (
    <AuthContext.Provider value={{ 
      auth: authToken, 
      setAuth, 
      logout, 
      login, 
      register,
      updateWallet,
      getToday,
      getStart,
      getEnd,
      getUser,
      updateStartDate,
      updateCounter,
      updateLock,
      available,
      errResponse,
      user,
      getWallet,
      resultWallet,
      isInitialLoading,
      isLoading,
      timestart,
      setTimeStart,
      end,
      setEnd
    }}>
      {children}
    </AuthContext.Provider>
  );
};