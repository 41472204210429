import { useState, useEffect, useRef } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from "react-bootstrap/Image";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from 'react-input-mask';
import { useAuth } from "../context-api/AuthConsumer";
import { useNavigate } from "react-router-dom";
import Tiktok from "../img/tiktok.png"
import {Oval} from "react-loader-spinner";

const Register = () => {
    const userRef = useRef();
    const { register, setAuth, errResponse } = useAuth();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        username: "",
        password: "",
        matchPwd: "",
        email: "",
        cpf: "",
        ddd: "",
        whatsapp: "",
        data_nascimento: "",
        genero: "",
        timestart: "",
        timeend: ""

    });
    const [formerrors, setFormErrors] = useState({});
    const [errMsg, setErrMsg] = useState("");
    const [loading, setLoading] = useState(false)

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.id]: event.target.value
        });
      };

    const handleOptions = (event) => {
       setValues({
        ...values,
        [event.value]: event.value
       })
    }

    const validate = () => {
        let errors = {};

        //name field
        if (!values.username) {
            errors.username = "Username is required";
        }

        //password field
        if (!values.password || !values.matchPwd) {
            errors.password = "Password is required";
        } else if (values.matchPwd !== values.password) {
            errors.matchPwd = "Confirm password should be equal Password";
        }

        //email field
        if (!values.email) {
            errors.email = "Email address is required";
        } else if (!/\S+@\S+\.\S+/.test(values.username)) {
            errors.email = "Email address is invalid";
        }

        //cpf field
        if (!values.cpf) {
            errors.cpf = "CPF is required";
        } else if (!/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/.test(values.cpf)){
            errors.cpf = "Insert a valid CPF";
        }

        if(values.genero.length === 0){
            // errors.genero = "O campo não pode estar vazio"
            values.genero = "feminino"
        }

        //phone field
        // if (!values.whatsapp) {
        //     errors.whatsapp = "Whatsapp is required";
        // } else if(!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3,5}[-\s\.]?[0-9]{4,6}$/.test(values.whatsapp)) {
        //     errors.whatsapp = "Insert a valid Whatsapp";
        // }

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();

        if (validate(values)) {
                setLoading(true)
                const userToken = await register(
                    values.username,
                    values.password,
                    values.email,
                    values.cpf,
                    values.ddd,
                    values.whatsapp,
                    values.data_nascimento,
                    values.genero,
                    values.timestart,
                    values.timeend
                    )
                if(userToken) {
                    setAuth(userToken)
                    //clear state and controlled inputs
                    setValues({
                        username: "",
                        password: "",
                        matchPwd: "",
                        email: "",
                        cpf: "",
                        ddd: "",
                        whatsapp: "",
                        data_nascimento: "",
                        genero: "",
                        timestart: "",
                        timeend: ""
                    })
                    // navigate('/home', { replace: true });
                } 
                // else {
                //     setErrMsg("User not found");
                //     throw new Error("User not found")
                // }
                // navigate('/home', { replace: true });
        } else {
            console.log("aqui")
            setErrMsg("Registration Failed");
        }
    };

    

    const handleLoading = () => {
        return (
            <Oval/>
        )
    }

    // State events
    useEffect(() => {
        userRef.current?.focus();
    }, []);

    return (
      <>
        <Row className="register m-auto ">
          <Col className="col-12" style={{  justifyContent:'center', alignItems:'center', alignContent:'center'}}>
            <Image
                src={Tiktok}
                rounded style={{width:'20rem', marginLeft:'.7rem',justifyContent:'center', alignItems:'center', alignContent:'center'}}
            />
          </Col>
          <Col className="maincol mb-3" style={{marginLeft:'.7rem',justifyContent:'center', alignItems:'center', alignContent:'center'}}>
            <Alert className="col-12 text-center" style={{justifyContent:'center', alignItems:'center', alignContent:'center'}} variant={'primary'}>
                Cadastre-se já na plataforma Tiktok Pay.
                <a href="/" style={{fontWeight:'bold', color:'blue', justifyContent:'center', display:'block', alignItems:'center', alignContent:'center'}}>
                    Já possuo uma conta!
                </a>
            </Alert>

              <Form className="col-12 mb-3" style={{borderStyle:'solid', borderRadius:'0.5rem',borderColor:'white',boxShadow:'10px 10px 50px #aaaaaa',paddingTop:'3rem',paddingBottom:'3rem',paddingLeft:'2rem',paddingRight:'2rem', display:'block'}} onSubmit={(e)=>handleSubmit(e)}>
                <Form.Group className="page-section bg-light" controlid="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <br/>
                    <Form.Control
                        style={{height:'2rem', width:'100%',}}
                        placeholder="Digite seu Email"
                        type="text"
                        id="username"
                        ref={userRef}
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.username.toLowerCase()}
                        aria-invalid={!!formerrors.email ? "false" : "true"}
                        aria-describedby="uidnote"
                        required
                        />
                    {formerrors.email && (
                        <p className="text-danger">{formerrors.email}</p>
                    )}
              </Form.Group>
              <Form.Group className="page-section bg-light" controlid="formBasicEmail">
                  <Form.Label>Nome</Form.Label>
                  <br/>
                  <Form.Control
                      style={{height:'2rem', width:'100%',}}
                      placeholder="Digite seu Nome"
                      type="text"
                      id="email"
                      ref={userRef}
                      autoComplete="off"
                      onChange={handleChange}
                      value={values.email}
                      aria-invalid={!!formerrors.username ? "false" : "true"}
                      aria-describedby="uidnote"
                      required
                    />
                    {formerrors.username && (
                        <p className="text-danger">{formerrors.username}</p>
                    )}
              </Form.Group>
              <Form.Group className="page-section bg-light" controlid="formBasicEmail">
                    <Form.Label>CPF</Form.Label>
                    <br/>
                    <Form.Control
                        style={{height:'2rem', width:'100%',}}
                        placeholder="Digite seu CPF"
                        type="text"
                        id="cpf"
                        ref={userRef}
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.cpf}
                        aria-invalid={!!formerrors.cpf ? "false" : "true"}
                        aria-describedby="uidnote"
                        required
                    />
                    {formerrors.cpf && (
                        <p className="text-danger">{formerrors.cpf}</p>
                    )}
              </Form.Group>
              <Form.Group className="page-section bg-light" controlid="formBasicEmail">
                    <Form.Label>WhatsApp (apenas números)</Form.Label>
                    <br/>
                    <Row>
                    <Form.Control
                        className="ml-3"
                        style={{height:'2rem', width:'30%'}}
                        placeholder="DDD"
                        type="text"
                        id="ddd"
                        ref={userRef}
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.ddd}
                        maxLength="2"
                        aria-describedby="uidnote"
                        required
                    />
                    <InputMask
                        style={{height:'2rem', width:'60%',}}
                        id="whatsapp"
                        mask='99999-9999'
                        placeholder="99999-9999"
                        onChange={handleChange}
                        value={values.whatsapp}
                        aria-invalid={!!formerrors.whatsapp ? "false" : "true"}
                        aria-describedby="uidnote"
                        required>
                    </InputMask>
                    {formerrors.whatsapp && (
                        <p className="text-danger">{formerrors.whatsapp}</p>
                    )}
                    </Row>
              </Form.Group>
              <Form.Group className="page-section bg-light" controlid="formBasicEmail">
                    <Form.Label>Data de Nascimento</Form.Label>
                    <br/>
                    <InputMask
                        style={{height:'2rem', width:'100%'}}
                        id="data_nascimento"
                        mask='99-99-9999'
                        placeholder='DD-MM-YYYY'
                        value={values.data_nascimento}
                        onChange={handleChange}>
                    </InputMask>
              </Form.Group>
              <Form.Group className="page-section bg-light" controlid="formBasicEmail">
                    <Form.Label>Gênero</Form.Label>
                    <br/>
                    <select aria-invalid={!!formerrors.genero ? "false" : "true"} required style={{height:'2rem', width:'100%',}} id="genero" value={values.genero === '' ? 'feminono' : values.genero} onChange={handleChange}>
                        <option value="feminino">Feminino</option>
                        <option value="masculino">Masculino</option>
                    </select>
                    {formerrors.genero && (
                        <p className="text-danger">{formerrors.genero}</p>
                    )}
              </Form.Group>
              <br/>
              <Form.Group className="mb-3" controlid="formBasicPassword">
                    <Form.Label>Senha</Form.Label>
                    <br/>
                    <Form.Control
                        style={{height:'2rem', width:'100%',}}
                        placeholder="Digite sua senha"
                        type="password"
                        id="password"
                        onChange={handleChange}
                        value={values.password}
                        required
                        aria-invalid={!!formerrors.password ? "false" : "true"}
                        aria-describedby="pwdnote"
                    />
                    {formerrors.password && (
                        <p className="text-danger">{formerrors.password}</p>
                    )}
              </Form.Group>
              <Form.Group className="mb-3" controlid="formBasicPassword">
                    <Form.Label>Confirmar Senha</Form.Label>
                    <br/>
                    <Form.Control
                        style={{height:'2rem', width:'100%',}}
                        placeholder="Confirmar senha"
                        type="password"
                        id="matchPwd"
                        onChange={handleChange}
                        value={values.matchPwd}
                        required
                        aria-invalid={!!formerrors.matchPwd ? "false" : "true"}
                        aria-describedby="confirmnote"
                    />
                    {formerrors.matchPwd && (
                        <p className="text-danger">{formerrors.matchPwd}</p>
                    )}
              </Form.Group>
              <br/>
              {!loading || errResponse ?
                <Button type="submit" onClick={() => {}} style={{ color :'white', backgroundColor:'black', borderRadius:'0.3rem', width:'100%', height:'2.5rem' }}>
                  Cadastre
                </Button> :
              <div style={{width:'100%', height:'2.5rem', justifyContent:'center', display:'flex', alignContent:'center', alignItems:'center'}}>
                  <Oval
                    secondaryColor="#ff174d"
                    color="#00eae7"
                    width={50}
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
              </div>
               }
              {errMsg && (
                <p className="text-danger">{errMsg}</p>
              )}
              </Form>
          </Col>
        </Row>

      </>
    );
  };

  export default Register;